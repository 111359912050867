<template>
  <div class="main-layout select-scenario">
    <h1>{{ heading }}</h1>
    <div id="scenario-optionset">
      <div
        :class="'scenario ' + hovered(id)"
        v-for="(item, id) in scenarios"
        v-bind:key="id"
        @mouseenter="hoverItem(id)"
        @mouseout="hoverItem(null)"
      >
        <div
          :id="'scenario-' + id"
          :style="cardBackground(item)"
          v-bind:key="hover"
        >
          <div class="details">
            <h2>{{ item.title || "[untitled]" }}</h2>
            <div class="description">{{ description(item) }}</div>
          </div>
          <button
            class="wide"
            @mousedown.prevent="selectScenario(id)"
            v-if="!scenarioId"
          >
            Select
          </button>
          <button class="wide" disabled="disabled" v-if="scenarioId == id">
            Loading...
          </button>
        </div>
      </div>
    </div>

    <div id="integrated-scenarios">
      <div
        :class="'scenario ' + hovered(id)"
        v-for="(item, id) in integrated"
        v-bind:key="id"
        @mouseenter="hoverItem(id)"
        @mouseout="hoverItem(null)"
      >
        <div
          :id="'scenario-' + id"
          :style="background(item)"
          v-bind:key="integratedHover"
        >
          <div class="details">
            <h2>{{ item.title || "[untitled]" }}</h2>
            <div class="description">{{ description(item) }}</div>
          </div>
          <div class="buttons">
            <button
              class="wide"
              @mousedown.prevent="selectScenario(id)"
              v-if="!scenarioId"
            >
              Select
            </button>
            <button class="wide" disabled="disabled" v-if="scenarioId == id">
              Loading...
            </button>
          </div>
        </div>
      </div>
    </div>
    <main-layout></main-layout>
  </div>
</template>

<script>
import MainLayout from "../components/MainLayout";
import { currentUser } from "../store";
import { hoverItem, hovered } from "../helpers";

export default {
  components: {
    MainLayout,
  },
  data: function () {
    return {
      heading: "Loading scenarios...",
      scenarios: {},
      integrated: {},
      site: {},
      hover: 0,
      integratedHover: 1,
      scenarioId: null,
    };
  },
  created: function () {
    if (this.$store.state.currentProject.site) {
      this.$store
        .dispatch("loadSite", {
          _id: { $oid: this.$store.state.currentProject.site },
        })
        .then(() => {});
    }

    this.$store
      .dispatch(
        "loadScenarioOptions",
        this.$store.state.currentProject._id.toString()
      )
      .then((scenarios) => {
        for (const s in scenarios) {
          if (scenarios.hasOwnProperty(s)) {
            if (
              scenarios[s].keyIndicators &&
              scenarios[s].keyIndicators.length > 0
            ) {
              this.scenarios[s] = scenarios[s];
            } else {
              this.integrated[s] = scenarios[s];
            }
          }
        }
        this.heading = this.$store.state.currentProject.frontPageTitle
          ? this.$store.state.currentProject.frontPageTitle
          : "Select a challenge!";
      })
      .catch((e) => {
        console.log(e);
      });
  },
  methods: {
    hovered(id) {
      return hovered(id);
    },
    hoverItem(id) {
      hoverItem(id);
      this.hover += 1;
      this.integratedHover += 1;
    },
    cardBackground(item) {
      if (item.color) {
        let b = "background: ";
        if (hovered(item._id.toString())) {
          b += "rgba(255, 255, 255, 0.5) ";
        }
        b += "url('/images/background/card_" + item.color + ".jpg');";
        return b;
      }
    },
    background(item) {
      if (item.color) {
        let b = "background: ";
        if (hovered(item._id.toString())) {
          b += "rgba(255, 255, 255, 0.5) ";
        }
        b += "url('/images/background/" + item.color + ".jpg');";
        return b;
      }
    },
    description(scenario) {
      if (scenario.summary) return scenario.summary;
      if (scenario.description) {
        return scenario.description.length < 200
          ? scenario.description
          : scenario.description.substring(
              0,
              scenario.description.indexOf(".") + 1
            );
      }
    },
    selectScenario: function (scenarioId) {
      if (this.scenarioId) return;
      this.scenarioId = scenarioId;
      this.$store
        .dispatch("loadScenario", scenarioId)
        .then(() => {
          this.$store
            .dispatch("createSolution", {
              owner: currentUser().id,
              testSolution: currentUser().customData.admin,
              scenario: this.$store.state.scenario._id.toString(),
              timeline:
                this.$store.state.scenario.startYear ||
                new Date().getFullYear(),
              budget: this.$store.state.scenario.budgetPerPhase,
              implementations: { type: "FeatureCollection", features: [] },
            })
            .then((solution) => {
              this.$store.commit("setSolution", solution);
              this.$store.commit("setCurrentPage", "/ExploreSite");
              this.$router.push({
                path: "/ExploreSite",
              });
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style lang="scss">
@import "../styles/base.scss";
@import "../styles/game-components.scss";

.select-scenario {
  padding-top: 103px;
  h1 {
    margin-bottom: 40px;
  }

  .scenario {
    button {
      margin: 0;
      width: 220px;
      flex-basis: 200px;
    }
    h2 {
      font-size: 24px;
      line-height: 32px;
      margin: 20px 0;
    }
    .description {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 23px;
      /* or 171% */

      text-align: center;
      margin-bottom: 50px;
      overflow: auto;
    }
    > div {
      border-radius: 15px;
      border-radius: 15px;
      padding: 40px 26px 30px 26px;
      text-align: center;
      flex: none;
      align-self: center;
      margin: 0px 10px;
    }
    &.active {
      filter: drop-shadow(0px 13.8258px 41.4774px rgba(73, 92, 136, 0.15));
      background-color: none;
    }
    &.inactive {
      filter: none;
      > div {
        background-color: rgba(255, 255, 255, 0.5);
        background-blend-mode: overlay;
      }
    }
  }
}

// .select-scenario #about {
// }
// .select-scenario #sun, .select-scenario #cloud, .select-scenario #cloud2 {
// 	display: none;
// }

/*.scenario:hover {
	width: 380px;
	margin-top: -40px;
	transition-property: margin-top, width;
	transition-duration: 0.5s;


}
.scenario:hover > div {
	height: 500px;
	transition-property: height;
	transition-duration: 1s;
}
.scenario:hover .details {
	height: 400px;
	transition-property: height;
	transition-duration: 1s;
}
.scen
*/
#scenario-optionset {
  background: white;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: fit-content;
  display: flex;
  flex-wrap: nowrap;
  .scenario {
    width: 300px;
    margin-right: 40px;
    .details {
      height: 340px;
    }
    & > div {
      height: 420px;
    }
  }
}

#integrated-scenarios {
  margin: 60px auto 60px auto;
  max-width: 1053px;

  .scenario {
    & > div {
      text-align: left;
      display: flex;
      justify-content: space-between;
    }
    h1,
    .description {
      text-align: left;
      flex-basis: 530px;
    }
    h2 {
      margin-top: 0px;
    }

    .details {
      flex-basis: auto;
    }
  }
}
</style>
